import React, { useState, useRef, useEffect } from "react"
// import { graphql, Link } from 'gatsby';
import { graphql } from "gatsby"
import useSiteMetadata from "../hooks/use-site-metadata"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ButtonChecked from "../components/psu-brand/button-checked"
// import StyledTextWrap from '../components/psu-brand/styled-text-wrap';
import { BsCheck } from "@react-icons/all-files/bs/BsCheck" // https://react-icons.github.io/react-icons
import HeroFeatureStory from "../components/psu-brand/hero-feature-story"
import ArticleSummary from "../components/psu-brand/article-summary"

const ResourcePage = ({ location, data }) => {
  const { siteUrl } = useSiteMetadata()
  const urlParams = new URLSearchParams(location.search)
  const tagParam = urlParams.get("tag")

  // keep track of selected tags/items in state
  const [items, setItems] = useState(data.craftAPI.entries)
  const [selectedTags, setSelectedTags] = useState(tagParam ? [tagParam] : [])
  const [showAllTags, setShowAllTags] = useState(true)
  let isMounted = useRef(false)

  // render entries for individually selected tags
  // const filterItems = useCallback(() => {
  useEffect(() => {
    // prevent from running on initial mount so all entries/tags can be displayed
    if (isMounted.current) {
      const newItems = data.craftAPI.entries.filter(entry => {
        let flatEntryTags = entry.tags.map(tagObj => tagObj.title)
        let flatEntryCats = entry.systemsCategory.map(catObj => catObj.title)
        let flatTagCats = flatEntryTags.concat(flatEntryCats)
        let result = selectedTags.length
          ? selectedTags.every(function (element) {
              return flatTagCats.includes(element)
            })
          : false
        return result
      })
      setItems([...newItems])
    } else {
      isMounted.current = true
    }
  }, [selectedTags, data.craftAPI.entries])

  // render all entries
  useEffect(() => {
    if (showAllTags) {
      setItems([...data.craftAPI.entries])
    } else {
      // setItems([]);
      setSelectedTags([...selectedTags])
    }
  }, [showAllTags, data.craftAPI.entries])

  const addSelectedTag = tag => {
    // console.log('tag: ', tag);
    // console.log('selectedTags.includes(tag): ', selectedTags.includes(tag));
    let newSelectedTags = []
    if (!selectedTags.includes(tag)) {
      newSelectedTags = [...selectedTags]
      newSelectedTags.push(tag)
    }

    setSelectedTags(newSelectedTags)
  }
  const removeSelectedTag = tag => {
    let newSelectedTags = selectedTags.filter(t => t !== tag)
    setSelectedTags(newSelectedTags)
  }
  const filterController = (checked, text, id, e) => {
    // e.preventDefault();
    // set the selected tags based on if the button is checked
    setShowAllTags(false)
    checked ? addSelectedTag(text) : removeSelectedTag(text)
  }

  useEffect(() => {
    if (urlParams.get("tag") !== null) {
      setSelectedTags([urlParams.get("tag")])
      setShowAllTags(false)
    }
  }, [])

  return (
    <Layout location={location}>
      <Seo title="Resource Database - Virtual Farm" />
      <HeroFeatureStory
        title="Resource Database"
        subheading="Filterable Collection of Educational Bulletins and Fact Sheets"
        sectionOverride="Resources"
        children={<p></p>}
        image={[
          {
            extension: "jpg",
            filename: "150-farm.jpg",
            focalPoint: [0.5, 0.5],
            hasFocalPoint: false,
            path: "150-farm.jpg",
            title: "150 farm",
            width: 3840,
            url: `${siteUrl}/uploads/150-farm.jpg`,
            srcset: `${siteUrl}/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, ${siteUrl}/uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, ${siteUrl}/uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, ${siteUrl}/uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, ${siteUrl}/uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w`,
          },
        ]}
        key="resource-hero"
      />

      <div className="container my-4">
        {/* <div className="content-block">
					<h1>Resource Database</h1>
					<p className="h3">
						Filterable Collection of Educational Bulletins and Fact
						Sheets
					</p>
				</div> */}
        <div className="tags mt-4">
          {/* all articles button */}
          <a
            href="#"
            key="all-articles"
            id="all articles"
            className="me-2 mb-2 mt-0 py-1 px-2 button button-light-bkg"
            onClick={e => {
              e.preventDefault()
              setShowAllTags(!showAllTags)
            }}
          >
            {showAllTags ? <BsCheck /> : ""}
            All Articles
          </a>
          {/* <ButtonChecked
						// ref={allArticles}
						text="All Articles"
						id="all articles"
						key="all-articles"
						// clickCallback={filterController}
						clickCallback={() => setShowAllTags(!showAllTags)}
						className="me-3 mb-3"
						checkedProp={showAllTags}
					/> */}

          {/* systems categories buttons */}
          <h2 className="h5 mt-4">Systems</h2>
          {data.craftAPI.categories.map(cat => {
            // console.log('resource cat: ', cat);

            return (
              <>
                {/* <button>{cat.title}</button> */}
                <ButtonChecked
                  text={cat.title}
                  id={cat.id}
                  key={cat.id}
                  clickCallback={filterController}
                  className="me-2 mb-2 mt-0 py-1 px-2"
                  // onClick={() => filterItems(cat.title)}
                  // onClick={() =>
                  // 	filterController(
                  // 		selectedTags.includes(cat.title),
                  // 		cat.title,
                  // 		cat.id
                  // 	)
                  // }
                  checkedProp={selectedTags.includes(cat.title)}
                />
              </>
            )
          })}

          {/* tag buttons */}
          <h2 className="h5 mt-4">Tags</h2>
          {data.craftAPI.tags.map(tag => {
            // console.log('resource tag: ', tag);

            return (
              <>
                {/* <button>{tag.title}</button> */}
                <ButtonChecked
                  text={tag.title}
                  id={tag.id}
                  key={tag.id}
                  clickCallback={filterController}
                  className="me-2 mb-2 mt-0 py-1 px-2"
                  // onClick={() => filterItems(tag.title)}
                  // onClick={() =>
                  // 	filterController(
                  // 		selectedTags.includes(tag.title),
                  // 		tag.title,
                  // 		tag.id
                  // 	)
                  // }
                  checkedProp={selectedTags.includes(tag.title)}
                />
              </>
            )
          })}
        </div>

        {/* resource entries */}
        {items.length === 0 ? (
          <h3 className="my-3" data-aos="fade-in">
            Sorry, no matching resources were found.
          </h3>
        ) : (
          <h3 className="my-3" data-aos="fade-in">
            {items.length} matching{" "}
            {items.length === 1 ? "resource was " : "resources were "}
            found.
          </h3>
        )}
        {/* {data.craftAPI.entries.map((entry) => { */}
        {items.map(entry => {
          // console.log('resource entry: ', entry);
          let author,
            source,
            description,
            link = ""

          entry.resourceBuilder.map(block => {
            switch (block.typeHandle) {
              case "author":
                author = block.text
                break
              case "source":
                source = block.text
                break
              case "description":
                description = block.text
                break
              case "link":
                link = block.linkUrl
                break
            }
            return null
          })

          return (
            <>
              {/* <div className="entry" key={entry.id}>
								<h2>{entry.title}</h2>
								<ul>
									{entry.tags.map((tag) => (
										<li key={tag.id}>{tag.title}</li>
									))}
								</ul>
							</div> */}
              <ArticleSummary
                author={author}
                source={source}
                title={entry.title}
                description={description}
                link={link}
              />
            </>
          )
        })}
      </div>
      {/* <ContentBuilder {...data.craftAPI.entry} /> */}
    </Layout>
  )
}

export const query = graphql`
  query ResourcesQuery {
    craftAPI {
      categories(group: "systemsCategories") {
        id
        title
      }
      tags {
        id
        title
      }
      entries(section: "resources") {
        id
        title
        ... on CraftAPI_resources_default_Entry {
          id
          tags {
            id
            title
          }
          systemsCategory {
            id
            title
          }
          resourceBuilder {
            ... on CraftAPI_resourceBuilder_subtitle_BlockType {
              id
              text
              typeHandle
            }
            ... on CraftAPI_resourceBuilder_author_BlockType {
              id
              text
              typeHandle
            }
            ... on CraftAPI_resourceBuilder_source_BlockType {
              id
              typeHandle
              text
            }
            ... on CraftAPI_resourceBuilder_relatedEntries_BlockType {
              id
              typeHandle
              entry {
                id
                title
                slug
              }
            }
            ... on CraftAPI_resourceBuilder_link_BlockType {
              id
              typeHandle
              title
              linkUrl
            }
            ... on CraftAPI_resourceBuilder_fileUpload_BlockType {
              id
              typeHandle
              title
            }
            ... on CraftAPI_resourceBuilder_resourceType_BlockType {
              id
              typeHandle
              title
            }
            ... on CraftAPI_resourceBuilder_description_BlockType {
              id
              text
              typeHandle
            }
            ... on CraftAPI_resourceBuilder_year_BlockType {
              id
              typeHandle
              number
            }
            ... on CraftAPI_resourceBuilder_location_BlockType {
              id
              typeHandle
              text
            }
          }
        }
      }
    }
  }
`

export default ResourcePage
