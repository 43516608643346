import React, { useEffect, useState, useRef } from 'react';
import './button.scss';

// https://react-icons.github.io/react-icons
import { BsCheck } from '@react-icons/all-files/bs/BsCheck';

function Button({
	text = 'Discover',
	icon = 'icon-angle-right',
	style = 'button-light-bkg',
	linkUrl = '#',
	id,
	className,
	checkedProp = false,
	clickCallback
}) {
	const [checked, setChecked] = useState(checkedProp);
	const isMounted = useRef(false);

	// const clickHandler = () => {
	// 	setChecked(!checked);
	// };

	useEffect(() => {
		console.log('useEffect checked state: ', checked);
		if (isMounted.current && typeof clickCallback === 'function') {
			clickCallback(checked, text, id);
		} else {
			isMounted.current = true;
		}
	}, [checked]);

	return (
		<a
			href={linkUrl}
			className={`button ${style} ${icon} ${className} ${
				checked ? 'active' : ''
			}`}
			// data-aos="fade-in"
			key={id}
			// onClick={clickHandler}
			onClick={(e) => {
				e.preventDefault();
				setChecked(!checked);
			}}
		>
			{checked ? <BsCheck /> : ''}
			{text}
		</a>
	);
}

export default Button;
